// contactIconsList.js
import {
  createStyles,
  ThemeIcon,
  Text,
  Box,
  Stack,
} from "@mantine/core";
import { IconSun, IconPhone, IconMapPin, IconAt } from "@tabler/icons-react";

const ContactIconStyles = {
  white: "white",
  gradient: "gradient",
};

const useStyles = createStyles((theme, { variant }) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.white,
  },
  icon: {
    marginRight: theme.spacing.md,
    backgroundImage:
      variant === ContactIconStyles.gradient
        ? `linear-gradient(135deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
            theme.colors[theme.primaryColor][6]
          } 100%)`
        : "none",
    backgroundColor: "transparent",
  },
  title: {
    color:
      variant === ContactIconStyles.gradient
        ? theme.colors.gray[6]
        : theme.colors[theme.primaryColor][0],
  },
  description: {
    color: variant === ContactIconStyles.gradient ? theme.black : theme.white,
  },
}));

function ContactIcon({
  icon: Icon,
  title,
  description,
  variant = ContactIconStyles.gradient,
  className,
  ...others
}) {
  const { classes, cx } = useStyles({ variant });
  return (
    <div className={cx(classes.wrapper, className)} {...others}>
      {variant === ContactIconStyles.gradient ? (
        <ThemeIcon size={40} radius="md" className={classes.icon}>
          <Icon size="1.5rem" />
        </ThemeIcon>
      ) : (
        <Box mr="md">
          <Icon size="1.5rem" />
        </Box>
      )}
      <div>
        <Text size="xs" className={classes.title}>
          {title}
        </Text>
        <Text className={classes.description}>{description}</Text>
      </div>
    </div>
  );
}

const MOCKDATA = [
  { title: "Email", description: "contact@codelogic.tn", icon: IconAt },
  { title: "Phone", description: "+216 (55) 208 206", icon: IconPhone },
  {
    title: "Address",
    description:
      "Immeuble Henda 3éme etage Bloc A bureau A1, 10 rue l ile de Rhodes, " +
      "Les berges du lac 2, 1053 Tunis",
    icon: IconMapPin,
  },
  { title: "Working hours", description: "8 am – 10 pm", icon: IconSun },
];

function ContactIconsList({ data = MOCKDATA, variant }) {
  const items = data.map((item, index) => (
    <ContactIcon key={index} variant={variant} {...item} />
  ));
  return <Stack>{items}</Stack>;
}

export default ContactIconsList;
