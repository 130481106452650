import React from "react";
import { Col, Row, Container, Nav } from "react-bootstrap";
import "./home-page.scss";
import HeaderNavbar from "../../components/header";
import CodeCard from "../../components/code-card";
import ServiceCard from "../../components/service-card";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Newsletter } from "../../components/newsletter";
import Footer from "../../components/footer";
import Typewriter from 'typewriter-effect';

const Index = () => {
  return (
    <>
      <HeaderNavbar />
      <section className="d-flex align-items-center hero-section">
        <Container>
          <Row id="hero">
            <Col lg={6} className="d-flex flex-column justify-content-center pt-lg-0">
              <h1>
                <span>CODE</span>
                <span style={{ color: "#38F1B9" }}>LOGIC</span>
              </h1>
              <h2 className="text-white">
                <Typewriter
                  options={{
                    strings: ['A consulting firm specialized in software solutions.'],
                    autoStart: true,
                    loop: true,
                    pauseFor: 10000,
                  }}
                />
              </h2>
              {/* <Nav.Link as={Link} to="/contact" className="no-underline fs-6">
                <button className="custom-btn btn-3">
                  <span>LET’S TALK <FontAwesomeIcon icon={faArrowRight} /></span>
                </button>
              </Nav.Link> */}
            </Col>
            <Col lg={6} data-aos="zoom-in" data-aos-delay="200" className="hero_section_col_right">
              <div className="hero_section_right_side text-center text-lg-left mb-sm-5 pb-md-5 mb-sm-5 pb-md-5">
                <div className="bkPsUw mockup1">
                  <img src="/mockups/mockup-card.svg" alt="Mockup" />
                </div>
                <div className="hKkpRc mockup2">
                  <img src="/mockups/mockup-card2.svg" alt="Mockup" />
                </div>
                <div className="qttSU mockup3">
                  <img src="/mockups/mockup-content.svg" alt="Mockup" />
                </div>
                <div className="csUyAM mockup4">
                  <img src="/mockups/mockup2-bg.svg" alt="Mockup" />
                </div>
                <div className="kPVVkw mockup5">
                  <img src="/mockups/mockup3-bg.svg" alt="Mockup" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <header className="main-header mt-sm-5 pt-sm-5">
        <div className="main-container gutter-3">
          {/* start of codepan part*/}
          <div className="codepan">
            <div className="back-board"></div>

            <CodeCard cardClassName="top" title="HTML">
              <div className="">
                <code>
                  <span className="c-div-color">&lt;div </span>
                  <span className="c-class-color">class</span>
                  <span>=</span>
                  <span className="c-class-content-color">"rect"</span>
                  <span className="c-div-color">&gt;&lt;/div&gt;</span>
                </code>
              </div>
            </CodeCard>

            <CodeCard cardClassName="center" title="SCSS">
              <div className="">
                <code>
                  <p className="line1-anim">
                    <span className="c-class-color">.rect </span>
                    <span>{"{"}</span>
                  </p>
                  <br />
                  <p className="ml-2 line2-anim">
                    <span className="c-css-selector-color">background</span>
                    <span>:</span>
                    <span className="c-class-color"> linear-gradient</span>(
                  </p>
                  <br />
                  <p className="ml-4 line3-anim">
                    <span className="c-css-value-color">-119deg</span>,
                  </p>
                  <br />
                  <p className="ml-4 line4-anim">
                    <span className="c-class-color">$gray</span>
                    <span className="c-css-value-color"> 0%</span>,
                  </p>
                  <br />
                  <p className="ml-4 line5-anim">
                    <span className="c-class-color">$dark-gray</span>
                    <span className="c-css-value-color"> 100%</span>); {"}"}
                  </p>
                </code>
              </div>
            </CodeCard>

            <CodeCard cardClassName="bottom" title="JS">
              <div className="HomepageAnonHeaderHero_codeContent-I6nz5">
                <code>
                  <span className="c-class-color">var </span>
                  <span className="c-script-variable-color">colors </span> = [
                  <span className="c-class-content-color">"#74B087"</span>,
                  <span className="c-class-content-color">"#DE7300"</span>,
                  <span className="c-class-content-color">"#74B087"</span>];
                  <br />
                  <span className=""></span>
                  <br />
                  <span className="c-class-color">function </span>
                  <span className="c-script-variable-color">animate </span>
                  {"() {};"}
                </code>
              </div>
            </CodeCard>
          </div>
          {/* end of codepan part*/}
          {/* start of description part*/}
          <div className="description">
            <div className="gutter-1 d-flex text-center mt-5">
              <h1>
                TOGETHER WE <span style={{ color: "#38F1B9" }}>PUSH </span>YOU
                <span style={{ color: "#38F1B9" }}>.</span> <br />
                TOGETHER WE <span style={{ color: "#38F1B9" }}>THRIVE</span>!
              </h1>
            </div>
          </div>
          {/* end of description part*/}
          <img src="/mockups/lines.svg" alt="" className="img-anonHeader" />
        </div>
      </header>

      <div className="main-container service-container">
        <ServiceCard
          imgSrc="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/okf-growth.svg"
          title="Enhancement"
          buttonText="Try the Editor"
        >
          <p className="text-white fw-bold">
            During Discovery, Designing and Development phases, our technical
            team is always there to provide suggestions and edits to improvise
            their product in the best possible manner.
          </p>
        </ServiceCard>

        <ServiceCard
          imgSrc="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/okf-client-centric-development.svg"
          title="Client Centric Development"
          href="https://codepen.io/challenges/"
          buttonText="Join this Week’s Challenge"
        >
          <p className="text-white fw-bold">
            We craft the client's needs on web, mobile, and blockchain to tailor
            the solutions and enhance growth of the businesses.
          </p>
        </ServiceCard>

        <ServiceCard
          imgSrc="https://www.hyperlinkinfosystem.com/assets/frontend_assets/img/okf-qa.svg"
          title="Quality Deliverance"
          href="https://codepen.io/#trending"
          buttonText="Explore Trending"
        >
          <p className="text-white fw-bold">
            We believe in delivering quality products to our clients by assuring
            all their project specifications at the best industry competitive
            price.
          </p>
        </ServiceCard>
      </div>
      {/* <Container>
        <Newsletter />
      </Container> */}
      <Footer />
    </>
  );
};

export default Index;
