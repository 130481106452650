import React from "react";
import { Row, Container } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import HeaderAction from "../../components/header";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { ContactUs } from "../../components/contact-us";
import Footer from "../../components/footer";

const Contact = () => {
  return (
    <>
      <HeaderAction />
      <div className="App">
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "45vh",
          }}
        >
          <Row className="text-center text-white">
            <h1>
              <b>GET STARTED</b>
            </h1>
            <h6 className="mt-4">
              Schedule a meeting today to learn more about us or use our contact
              info below to reach out
            </h6>
            <Link
              to="https://calendly.com/codelogic_tn/30min"
              className="no-underline fs-6 mt-3"
              style={{ color: "#38F1B9" }}
              target="_blank"
            >
              <button class="custom-btn btn-3">
                <span>
                  <FontAwesomeIcon icon={faCalendarDays} /> SCHEDULE A MEETING
                </span>
              </button>
            </Link>
          </Row>
        </Container>
        <Container>
          <ContactUs />
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
