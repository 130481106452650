import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const HeaderNavbar = () => {
  return (
    <Navbar bg="transparent" expand="lg" variant="light">
      <Container>
        {/* Logo */}
        <Navbar.Brand>
          <Link to="/" className="text-white fs-5 no-underline">
            <b>
              <span>CODE</span>
              <span style={{ color: "#38F1B9" }}>LOGIC</span>
            </b>
          </Link>
        </Navbar.Brand>
        {/* Toggle button for responsive behavior */}
        <Navbar.Toggle className="custom-toggle" aria-controls="navbar-nav" />

        {/* Navbar sections */}
        <Navbar.Collapse id="navbar-nav">
          {/* Section Left */}
          <Nav className="me-auto">
            {/* Add your left section elements here */}
          </Nav>
          {/* Section Middle */}
          <Nav className="mx-auto">
            {/* Add your middle section elements here */}
            {/* <Nav.Link>
              <Link to="/" className="text-white fs-5 no-underline">
                <b>Home</b>
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="#" className="text-white fs-5 no-underline">
                <b>About</b>
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="#" className="text-white fs-5 no-underline">
                <b>Services</b>
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/contact" className="text-white fs-5 no-underline">
                <b>Contact</b>
              </Link>
            </Nav.Link> */}
          </Nav>
          {/* Section Right */}
          {/* <Nav className="ms-auto">
            <Nav.Link>
              <Link
                to="/contact"
                className="no-underline fs-6"
                style={{ color: "#38F1B9" }}
              >
                <b>
                  Start New Project <FontAwesomeIcon icon={faArrowRight} />
                </b>
              </Link>
            </Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderNavbar;
