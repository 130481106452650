import "./service-card.scss";

function ServiceCard(props) {
  const { title, imgSrc } = props;

  return (
    <>
      <div className="service-card">
        <div className="icon-container">
          <img src={imgSrc} alt="img" className="service_card_img" />
        </div>
        <h2 className="title">{title}</h2>
        {props.children}
      </div>

      <img
        src="/mockups/lines2.svg"
        alt="lines_image"
        className="HomepageAnonTopics_bg-2 mb-5 pt-5"
      />
    </>
  );
}

export default ServiceCard;
