import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import Contact from "./pages/contact";

const AppRouter = () => {
  const location = window.location;
  return (
    <Router>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
