import React from 'react';

const Footer = () => {
  return (
    <footer className="text-white text-center py-4 mt-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-start">
            <h5>
            <b>
              <span>CODE</span>
              <span style={{ color: "#38F1B9" }}>LOGIC</span>
            </b>
            </h5>
            <p>
              A consulting firm specialized in software solutions.
            </p>
          </div>
          {/* <div className="col-md-6 text-center">
            <h5>Address</h5>
            <address>
            Immeuble Henda 3éme etage, Bloc A bureau A1,<br />
            10 rue l'ile de Rhodes,<br />
            Les berges du lac 2, 1053 Tunis<br />
              Phone: (+216) 55 208 206<br />
              Email: contact@codelogic.tn
            </address>
          </div> */}
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          <div className="col">
            <p className="mb-0">&copy; {new Date().getFullYear()} CODELOGIC. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
